import React from 'react';
import Img from 'gatsby-image';

const InstagramFeed = ({ posts, title, ...other }) => (
  <article className="instagram-feed" {...other}>
    <h2 className="instagram-feed-title">{title}</h2>
    <ul className="instagram-feed-list">
      {posts.map(({ node: instaPost }) => (
        <li className="instagram-feed-item" key={instaPost.id}>
          <a href={`https://www.instagram.com/p/${instaPost.id}/`}>
            <Img
              className="u-image"
              fluid={instaPost.localFile.childImageSharp.fluid}
              alt={instaPost.caption}
            />
          </a>
        </li>
      ))}
    </ul>
  </article>
);

export default InstagramFeed;
